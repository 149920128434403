@import "../../scss/variables/variables";

.contact {
  grid-column: center-start / center-end;
  grid-row: 3 /4;

  font-size: 2rem;

  display: grid;
  grid-row-gap: 2rem;
  grid-template-areas:
    "heading heading"
    "hours map"
    "info map";

  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: $bp-medium) {
    grid-template-areas:
      "heading heading"
      "hours info"
      "map map";
  }
  &__heading {
    grid-area: heading;
    justify-self: center;
    padding-bottom: 3rem;
    h1 {
      font-weight: 400;
    }
  }
  &__hours {
    grid-area: hours;
    &__heading {
      font-weight: 400;
      font-size: 2.5rem;
      text-decoration: underline;
    }
    &__subheading {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }
  &__info {
    grid-area: info;
  }
  &__map {
    grid-area: map;
  }
}
