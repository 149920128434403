@import "../../../scss/variables/variables";

.panel {
  grid-column: 1/-1;
  padding: 0;
  padding-left: 20px;
}

.panelFooter {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin: 40px 20px;
  @media only screen and (max-width: 750px) {
    margin: 40px 20px;
  }
}

.delivery {
  display: grid;
  grid-template-columns: 50px 1fr;
  &__chkbox {
    background: #333;
    grid-column: 1 /2;
    justify-self: end;
    margin-right: 4rem;
    display: inline;
    justify-self: start;
    align-self: end;

    &-txt {
      grid-column: 2 /-1;
      align-self: center;
      font-size: 2.2rem;
    }
  }
}

.delivery_address {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 40px;
}
.address {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 20px;
  margin-top: 10px;

  &__searchLabel {
    font-size: 2.8rem;
  }

  &__selectedLabel {
    font-size: 2.8rem;
  }
}

.debugInfo {
  font-size: 3rem;
  padding-top: 40px;
}
.errorText {
  color: lighten(red, 20%);
  font-size: 3rem;
}
.suggestion-item {
  font-size: 1.8rem;

  &--active {
    font-size: 2.2rem;
  }
}

.selectedaddress {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 0px;
  margin-top: 10px;
  background-color: $color-grey-light-1;
  border-radius: 2rem;
  border: 1px solid orangered;
  //padding: 5px;

  &__heading {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  &__Label {
    font-size: 2.8rem;
    text-decoration: underline;
  }
  &__selectedValue {
    font-size: 2.4rem;
    font-weight: 500;
    color: $color-primary;
    padding-left: 20px;
  }

  &__otherInfoLabel {
    margin-top: 10px;
    font-size: 2rem;
    font-style: italic;
    padding-left: 20px;
  }
  &__streetTxt {
    margin-left: 10px;
  }
}

.ouraddress {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 40px;
  padding-top: 20px;
  &__heading {
    grid-column: 1/-1;
    font-size: 3rem;
    color: $color-primary;
    text-decoration: underline;
    padding-bottom: 20px;
  }

  &__txt {
    grid-column: 1/-1;
    font-size: 2.4rem;
    font-weight: 500;
    padding-left: 10px;
    padding-bottom: 20px;
  }
  &__phone {
    grid-column: 1/-1;
    font-size: 2.6rem;
    font-weight: 300;
    padding-left: 20px;
    padding-bottom: 20px;
  }
}

.delchk-paynow {
  display: grid;
  // grid-template-columns: repeat(autofill, minmax(400px, 1fr));
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
  column-gap: 3rem;

  &__error {
    color: red;
    font-size: 1.8rem;
    font-weight: 500;
  }
  &__rhs {
    //align-self: center;
    background-color: lighten(lightpink, 10%);
    padding: 3rem;
  }
  &__lhs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: lighten(lightgrey, 10%);
    padding: 3rem;

    @media screen and (max-width: 750px) {
      padding: 1rem;
    }
  }

  &__logocontainer {
    grid-column: 1 /-1;
  }
  &__logo {
    img {
      width: 5rem;
    }
  }

  &__card {
    grid-column: 1 /-1;
  }

  &__info {
    grid-column: 1 /-1;
    font-style: italic;
  }

  &__cvc {
    margin-top: 0.5rem;
    div {
      width: 14rem;
    }
  }

  &__buttons {
    margin-top: 2rem;
    grid-column: 1 /-1;
    display: flex;
    justify-content: space-between;
  }
}

.cashpay {
  display: grid;
  background-color: #ffe9ec;
  padding: 3rem;
  &__label {
    font-size: 2.2rem;
  }
}
.creditpay {
  display: grid;
  //background-color: #ededed;
  padding: 3rem;

  &__logocontainer {
    display: grid;
    padding-bottom: 30px;
  }

  &__logo {
    grid-column: 1/2;
    display: inline-block;
    padding-bottom: 30px;
  }
  &__amount {
    grid-column: 2/-1;
    display: inline-block;
    justify-self: start;
    font-size: 2.4rem;
    margin-left: 40px;
  }

  &__error {
    color: red;
    text-decoration: none i !important;
  }

  &__label {
    font-size: 2.2rem;
  }
  &__info {
    font-size: 2rem;
    font-style: italic;
    margin-bottom: 20px;
  }

  &__bigtext {
    font-size: 2.5rem;
    color: rgba(6, 6, 131, 0.733);
    font-weight: 700;
    font-style: italic;
    margin-bottom: 10px;
  }

  &__element {
    display: grid;
    grid-template-columns: 150px 150px;
    margin-top: 0.5rem;
    div {
      width: 14rem;
    }
  }
}
