@import "../../scss/variables/variables";

.categories {
  //grid-area: main;
  padding: 2rem;
  font-size: 3rem;

  grid-column: center-start / center-end;
  grid-row: 3 /4;
  margin: 3rem 0;
  z-index: 2;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));

  grid-gap: 3rem;

  &__heading {
    grid-column: 1 /-1;
    justify-self: center;
    text-align: center;
    //font-family: "Zilla Slab", serif;

    color: #23292c;
    font-size: 4rem;
    font-family: "Zilla Slab", serif;

    margin: 0 0 10px;
    line-height: 6rem;
  }
  &__svg {
    max-width: 2.5rem;
    max-height: 2.5rem;
    margin-top: 0.4rem;
    fill: $color-primary;
  }
  &__contact {
    color: $color-primary;
  }

  &__name {
    justify-self: center;
    align-self: end;
    width: 100%;
    font-family: $font-display;
    font-size: 1.6rem;
    letter-spacing: 0.5rem;
    text-align: center;
    padding: 1.25rem;
    background-color: $color-white;
    color: black;
    font-weight: 400;

    a {
      text-decoration: none;
      color: $color-black;
      font-family: "Zilla Slab", serif;
      font-weight: 400;
      font-size: 5rem;
    }
  }
}

.category {
  background-color: transparent;
  //background-color: white;
  display: grid;
  grid-row-gap: 2.5rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 450px repeat(1, 5rem);

  @media screen and (max-width: 700px) {
    grid-template-rows: auto;
    grid-template-rows: 400px repeat(1, 2rem);
  }

  &__img {
    width: 100%;
    grid-column: 1 /-1;
    grid-row: 1/2;
    z-index: 1;
  }

  &__name {
    grid-column: 1 /-1;
    grid-row: 1/2;
    z-index: 3;

    justify-self: center;
    align-self: end;
    width: 100%;
    font-family: $font-display;
    // font-family: "Zilla Slab", serif;
    font-size: 28px;
    //letter-spacing: 0.2rem;
    text-align: center;
    padding: 1.25rem;
    background-color: white;
    color: black;
    font-weight: 400;
    text-decoration: none;

    @media screen and (max-width: 700px) {
      font-size: 30px;
    }

    //transform: translateY(50%);
    a {
      text-decoration: none;
      color: black;
      //  font-family: "Zilla Slab", serif;
      //  font-weight: 300;
      //  font-size: 2.5rem;

      font-weight: 500;
      font-size: 2.5rem;
      //margin-left: 10rem;
      padding: 0;
    }
  }

  &__deals {
    color: red;
    border: 1px dashed orange;
  }

  &__detail_1,
  &__detail_2 {
    margin-top: 2.5rem;
  }
  &__detail_1,
  &__detail_2,
  &__detail_3,
  &__detail_4,
  &__detail_5,
  &__detail_6 {
    font-size: 1.5rem;
    margin-left: 2rem;
    display: flex;
    align-items: center;

    svg {
      fill: $color-primary;
      max-height: 2rem;
      max-width: 2rem;
      margin-right: 1rem;
    }
  }

  &__btn {
    grid-column: 1/-1;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    padding: 1.4rem 3rem;
    //max-height: 15rem;
  }
}

a.button-one {
  color: #23292c;
  font-size: 18px;
  padding: 10px 35px;
  border: 1px solid #ea462b;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin: 0;
}
a.button-one:hover {
  color: $color-white;
  background: #ea462b;
}
