@import "../../scss/variables/variables";
@import "../../scss/helpers/mixins";

.custProduct {
  grid-column: center-start / center-end;
  grid-row: 3 /4;
  font-size: 3rem;

  display: grid;
  // grid-template-columns: repeat(2, minmax(50rem, max-content));
  grid-template-columns: 50% 50%;
  grid-template-rows: 4rem 5rem 5rem 2rem max-content;

  @media screen and (max-width: 650px) {
    grid-template-rows: 6rem 5rem 7rem 3rem max-content;
    grid-template-columns: 40% 60%;
  }

  &__close {
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-content: center;
    margin-right: 5rem;
    margin-left: 5rem;
    margin-top: 0.3rem;
    padding: 0;
    font-size: 2rem;
  }

  &__heading {
    grid-row: 2/3;
    grid-column: 1/-1;
  }
  &__totals {
    grid-row: 3/4;
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }

  &__counter {
    margin: 10px;
    height: 40px;
    width: 150px;
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: lighten(orangered, $amount: 5);
    justify-items: center;
    border-radius: 30px;
  }

  &__totalamount {
    //  border-top: 1px dashed;
    // border-bottom: 1px dashed;
    font-weight: 400;
    font-size: 3rem;
    color: orangered;
    text-decoration: underline;
  }
  &__cart {
    align-self: center;
    justify-self: center;
  }
  &__seperator {
    grid-row: 4/5;
    grid-column: 1/-1;
    border-bottom: 1px dashed;
  }
}

.l-section {
  margin: 1rem;
  &__heading {
    text-decoration: none;
    font-size: 2rem;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    text-align: center;
    background-color: lighten($color-primary, 20%);
  }
  &__detail {
    padding: 5rem;
    font-size: 2.2rem;
  }
  &__h2 {
    font-weight: 300;
    padding-bottom: 60rem;
  }
  &__h4 {
    font-weight: 600;
    padding-right: 1rem;
  }
  &__p1 {
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    color: orangered;
    font-weight: 400;
  }

  &__totals {
    margin-top: 1rem;
    border-top: 1px dashed;
    border-bottom: 1px dashed;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }

  &__quantitycontainer {
    grid-column: 1/2;
    @include customProduct_qty_container();
  }
  &__totalamount {
    grid-column: 2/-1;
  }
  &__cart {
    grid-column: 1/-1;
    justify-self: end;
    margin-right: 2rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 650px) {
      justify-self: end;
    }
  }

  &__button {
    height: 4.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: none;
    border-radius: 2rem;
    background-color: lighten(orangered, 30%);
    outline: none;
    font-size: 1.6rem;
  }

  &__button:hover {
    background-color: lighten(orangered, 10%);
  }
}

.r-section {
  &__heading {
    text-decoration: none;
    font-size: 2rem;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    text-align: center;
    background-color: lighten($color-secondary, 20%);
  }

  &__detail {
    padding-top: 3rem;
    font-size: 1.8rem;
    margin: 0 3rem;
  }

  &__h4 {
    font-family: sans-serif;
    font-weight: 300;
    border-bottom: 1px solid orangered;
    font-size: 2rem;
    line-height: 2rem;
    margin-top: 0rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    color: #444;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-content: center;
  }

  &__h4__expanded {
    border-bottom: 0 dashed black;
  }
  &__h4__footer {
    border-top: 1px solid orangered;
    margin-top: 2rem;
  }

  &__p {
    display: grid;
    justify-items: center;
  }
  &__p input[type="radio"]:checked + label {
    font-weight: 500;
    color: orangered;
  }

  &__p input[type="radio"] + label {
    font-weight: initial;
    padding: 2rem;
  }
}

.quantitycounterwithsizes {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 6rem 18rem;
  grid-template-rows: 4rem 6rem 2rem;
  align-items: center;
  justify-items: start;
}

.quantitylabel {
  font-size: 1.8rem;
  grid-column: 1 / -1;
}

.selectionlabel {
  font-size: 1.7rem;
  grid-column: 1 / -1;
  font-weight: 300;
  font-style: italic;
  color: orangered;
  align-self: start;
}

.quantitycontainer {
  @include customProduct_qty_container();
}

.quantityscalecontainer {
  @include customProduct_qty_container();
}

.quantityscalecontainer input {
  //width: 8.5rem !important;
  //padding-right: 0rem !important;
  //@include customProduct_qty_scale_input();
}

.quantitycontainer button {
  background-color: orangered;
  color: #fff;
  width: 4rem;
  border: none;
  outline: none;
  border-radius: 5rem;
}

$size: 2.5rem;

.selected {
  background: lighten(orangered, 10%) !important;
}

div .halfleft {
  @include pizza-half-shape();
}

div .halfright {
  @include pizza-half-shape();
}

.right,
.left {
  height: $size * 2;
  width: $size;
}

.right {
  border-bottom-right-radius: $size * 2;
  border-top-right-radius: $size * 2;
}

.left {
  border-bottom-left-radius: $size * 2;
  border-top-left-radius: $size * 2;
}

.leftfull {
  border-bottom-left-radius: $size * 2;
  border-top-left-radius: $size * 2;
}
