@import ".././../scss/variables/variables";

.orderconfirm {
  grid-column: center-start / center-end;
  grid-row: 3 /4;

  margin-top: 20px;

  display: grid;
  //grid-template-rows: 7vh min-content 12vh;
  grid-template-columns: 1fr;

  &__backbutton {
    grid-column: 1/-1;
    display: flex;
    flex-direction: row-reverse;
  }

  &__header {
    grid-column: 1/-1;
    color: $color-green;
    font-size: 2rem;
    font-weight: 600;

    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    margin-right: 3rem;
  }
  &__subheader {
    grid-column: 1/-1;
    color: $color-orangered;
    font-size: 1.8rem;
    font-weight: 400;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-right: 3rem;
  }

  &__center {
    grid-column: 1/-1;
  }

  &__footer {
    color: $color-coral;
    grid-column: 1/-1;
    align-items: center;
    margin-right: 3rem;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
  }
}

.grid-product {
  &__image {
    img {
      max-width: 100%;
      max-height: 4.5rem;
      border-radius: 50%;
    }
  }
}

.order-grid {
  display: grid;
  margin: 0 auto;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  align-items: top;
  border-radius: 0.5rem;
  font-size: 1.8rem;

  @media (max-width: 800px) {
    background: transparent;
    grid-row-gap: 2rem;
    box-shadow: none;
  }

  &-row {
    display: grid;
    grid-template-columns: 1fr 5fr repeat(3, 2fr) 1fr;
  }

  &-cell {
    padding: 1rem;
    align-self: center;
  }
}

.grid-center {
  justify-content: center;
}

.order-totals-grid {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  margin-top: 2rem;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  align-items: end;
  border-radius: 0.5rem;
  font-size: 1.8rem;

  &-row {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 5fr 1fr 1fr;
    font-weight: 400;
  }

  &-space {
    grid-column: 1/2;
  }
  &-heading {
    grid-column: 2/3;
  }
  &-value {
    grid-column: 3/-1;
    margin-bottom: 3rem;
  }
}

.grid-checkout {
  display: grid;
  margin: 0 auto;
  align-items: end;
  border-radius: 0.5rem;
  font-size: 1.8rem;

  &-row {
    display: grid;
    //grid-template-columns: 1fr 3fr repeat(3, 1fr) 1fr;
    font-weight: 400;
  }

  &-cell {
    padding: 1rem;
    &:not(:last-child) {
      border-right: 0px solid #ddd;
    }
  }
  &-button {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid $color-green;
    border-radius: 0.6em;
    color: $color-green;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
    margin: 20px;
    padding: 1.2em 2.8em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }
}
