@import "../../scss/variables/variables";

.soundButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.stopSoundButton {
  border: none;
}
.enableSoundButton {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  background-color: lightblue;
}
.ordersPage {
  margin-top: 10rem;
  display: grid;
  justify-content: center;
  font-size: 2rem;
}

.neworder {
  background-color: $color-new;
}
.acceptorder {
  background-color: $color-inprocess;
}
.completed {
  background-color: $color-completed;
}
.timerset {
  background-color: $color-timer;
}

.neworderBtn {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: $color-new;
}
.acceptorderBtn {
  background-color: $color-inprocess;
}
.completedBtn {
  background-color: $color-completed;
}

.mysegmentGroup {
  border: 1px solid black;
  border-radius: 6px;
  margin-bottom: 4rem;
  padding: 2rem;
}

.itemGroup {
  background-color: $color-grey-light-1;
  padding: 2rem;
  border-radius: 6px;

  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__desc {
    display: flex;
    justify-content: space-between;
  }
  &__heading {
    font-weight: 600;
  }
  &__value {
    color: red;
  }
  &__greenvalue {
    color: green;
  }
}

.totalBill {
  margin-top: 2rem;
  background-color: $color-grey-light-1;
  display: flex;
  justify-content: space-around;
  font-weight: 500;
}

.firstRow {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.firstRowButtons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}
