@import "../../scss/variables/variables";
.menus {
  padding: 2rem;
  font-size: 3rem;

  grid-column: center-start / center-end;
  grid-row: 3 /4;
  margin: 3rem 0;
  z-index: 2;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 3rem;

  &__heading {
    grid-column: 1 /-1;
    justify-self: center;
  }
  &__subheading {
    grid-column: 1 /-1;
    justify-self: right;
    font-size: 2.5rem;
    font-weight: 400;

    a {
      text-decoration: none;
      color: tomato;
    }
  }
}

.menu {
  background-color: $color-grey-light-1;

  display: grid;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);

  &__img {
    width: 100%;
    grid-column: 1 /-1;
    grid-row: 1/2;
    z-index: 1;
  }

  &__name {
    grid-column: 1 /-1;
    grid-row: 1/2;
    z-index: 3;

    justify-self: center;
    align-self: end;
    width: 100%;
    font-family: $font-display;
    font-size: 1.6rem;
    letter-spacing: 0.5rem;
    text-align: center;
    padding: 1.25rem;
    background-color: lighten($color-secondary, 20%);
    color: $color-white;
    font-weight: 400;

    transform: translateY(50%);
  }

  &__subtext {
    grid-column: 1 /-1;
    margin: 1rem 2rem;
    font-size: 1.6rem;
    font-style: italic;
    color: darken($color-grey-light-2, 25%);
  }

  &__detail_1,
  &__detail_2,
  &__detail_3,
  &__detail_4,
  &__detail_5,
  &__detail_6 {
    font-size: 1.5rem;
    margin-left: 2rem;
    display: flex;
    align-items: center;

    svg {
      fill: $color-primary;
      max-height: 2rem;
      max-width: 2rem;
      margin-right: 1rem;
    }

    svg.pepperoni {
      fill: $color-pepperoni;
    }
    svg.hawaiin {
      fill: $color-hawaiin;
    }
  }

  &__btn {
    grid-column: 1/-1;
  }

  h5.pepperoni,
  button.pepperoni {
    background-color: $color-pepperoni;
  }

  h5.hawaiin,
  button.hawaiin {
    background-color: $color-hawaiin;
  }
}
