.home_banner {
  background: #111 url("../../images/banners/home_banner.jpg") center center
    no-repeat;

  background-size: auto;
  background-size: cover;

  //grid-area: deals;
  grid-column: full-start / full-end;
  grid-row: 2 /3;
}

.menu_banner {
  background: #111 url("../../images/banners/menu_banner.jpg") center center
    no-repeat;

  background-size: auto;
  background-size: cover;
  //grid-area: deals;
  grid-column: full-start / full-end;
  grid-row: 2 /3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.about_banner {
  background: #111 url("../../images/banners/menu_banner.jpg") center center
    no-repeat;

  background-size: auto;
  background-size: cover;
  //grid-area: deals;
  grid-column: full-start / full-end;
  grid-row: 2 /3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contact_banner {
  background: #111 url("../../images/banners/menu_banner.jpg") center center
    no-repeat;

  background-size: auto;
  background-size: cover;
  //grid-area: deals;
  grid-column: full-start / full-end;
  grid-row: 2 /3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home_banner,
.menu_banner,
.about_banner,
.contact_banner {
  h1 {
    color: $color-white;
    font-size: 5rem;
    font-weight: 400;
    margin-left: 20rem;
  }
  h4 {
    color: $color-white;
    font-size: 3rem;
    font-weight: 300;
    margin-left: 40rem;
  }

  @media only screen and (max-width: $bp-medium) {
    h1 {
      font-size: 4rem;
      margin-left: 7rem;
    }
    h4 {
      font-size: 3rem;
      margin-left: 12rem;
    }
  }
}
