// COLOR VARIABLES
$bapoo-disclaimer: #161414;

$bappoo-color: orangered;
$color-primary: #fc7a01;
$color-success: green;

$bappoo-color: #684a2e;
$color-primary: #684a2e;
$color-success: #fc7a01;

$color-primary-dark: #b28451;

$color-new: #fcb2b2;
$color-inprocess: rgb(193, 223, 248);
$color-completed: rgb(221, 250, 221);
$color-timer: rgb(240, 233, 135);

$color-secondary: #fc0101;

$color-grey-light-1: #f9f7f6;
$color-grey-light-2: #aaa;

$color-faint-footer-grey: #f8f8f9;

$color-pepperoni: #aa4400;
$color-hawaiin: #99522b;

$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;
$color-coral: coral;
$color-orangered: orangered;
$color-green: green;
$color-tomato: tomato;
$color-red: red;
$color-light-blue: rgb(140, 210, 233);

$slider-header-color: #8c744c;
$slider-subheader-box-shadow: #dddddd;
$slider-item-box-shadow: #414004;
$slider-seletedItem: #a5a5a5;
$slider-item-border: #8c744c;
$slider-amount-bg-color: #f1f1f1;
$slider-amount-color: #333333;

$slider-header-color: $color-primary;
$slider-subheader-box-shadow: lighten($color-primary, 80%);
$slider-seletedItem: lighten($color-primary, 10%);
$slider-item-border: darken($color-primary, 5%);

// FONT VARIABLES
//the default font in our template - titles font
$font-primary: "Zilla Slab", serif;
// alternative font in our template - paragraphs font
$font-display: "Lato", sans-serif;

//RESPONSIVE BREAKPOINTS
$bp-largest: 75em; //1200 / 16
$bp-large: 62.5em; //1000 / 16
$bp-medium: 43.75em; //700 / 16
$bp-small: 37.5em; //600 / 16
$bp-exsmall: 31.25em; //500 / 16

/* https://codepen.io/FrankieDoodie/pen/BaBvPRd       css grid for card layouts */

$slider-width: 90vh;

@media only screen and (max-width: $bp-medium) {
  $slider-width: 48vh;
}
