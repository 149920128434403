@import "../../scss/variables/variables";
.sections {
  padding: 2rem;
  font-size: 3rem;

  grid-column: full-start / full-end;
  grid-row: 3 /4;
  margin: 3rem 0;
  z-index: 2;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));

  grid-gap: 3rem;
}

.paynow {
  background-color: white;
  display: grid;
  grid-template-columns: 1.3fr 2fr;
  grid-template-rows: 5rem 2rem 25rem 2rem 5rem;

  &__heading {
    grid-column: 1 / span 2;
    width: 100%;
    justify-self: center;
    text-decoration: none;
    font-size: 2rem;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    text-align: center;
    background-color: lighten(lightgreen, 5%);
  }
  &__error {
    grid-column: 1 / span 2;
    justify-self: center;
    margin-top: 2rem;
  }
  &__user_details {
    grid-column: 1 / 2;
    margin-left: 3rem;
    font-size: 2.2rem;
    margin-top: 0.9rem;

    input {
      border: none;
      outline: none;
      font-size: 2rem;
      color: black;
    }

    input::placeholder {
      color: rgb(140, 210, 233);
    }
  }

  &__card_details {
    grid-column: 2 / -1;
  }

  &__buttons {
    grid-column: 1 / span 2;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    padding: 1rem 1.5rem;
    color: black;
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    background-color: lighten(lightgreen, 5%);
  }
}

.paylater {
  background-color: white;
  display: grid;
  grid-template-columns: 1.3fr 2fr;
  grid-template-rows: 5rem 2rem 25rem 2rem 5rem;

  &__heading {
    grid-column: 1 / span 2;
    width: 100%;
    justify-self: center;
    text-decoration: none;
    font-size: 2rem;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    text-align: center;
    background-color: lighten($color-primary, 20%);
  }
  &__error {
    grid-column: 1 / span 2;
    justify-self: center;
    margin-top: 2rem;
  }
  &__user_details {
    grid-column: 1 / 2;
    margin-left: 3rem;
    font-size: 2.2rem;
    margin-top: 0.9rem;

    input {
      border: none;
      outline: none;
      font-size: 2rem;
      color: $color-black;
    }

    input::placeholder {
      color: $color-light-blue;
    }
  }

  &__card_details {
    grid-column: 2 / -1;
  }

  &__buttons {
    grid-column: 1 / span 2;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    padding: 1rem 1.5rem;
    color: $color-black;
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    background-color: lighten(lightgreen, 5%);
  }
}

.section {
  background-color: $color-white;
  //background-color: white;
  display: grid;
  grid-row-gap: 0.2rem;
  grid-template-columns: 1.3fr 2fr;

  &__error {
    margin-left: 20rem;
    grid-column: 1 / span 2;
  }
  @media screen and (max-width: 700px) {
    grid-template-rows: auto;
  }

  &__heading_1,
  &__heading_2 {
    width: 100%;
    grid-column: 1 /-1;
    grid-row: 1/2;
    z-index: 1;

    justify-self: center;
    align-self: end;
    text-decoration: none;
    font-size: 2rem;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    text-align: center;
    background-color: lighten($color-primary, 20%);
  }
  &__heading_1 {
    background-color: lighten(lightgreen, 5%);
  }

  &__detail {
    font-size: 1.8rem;
    margin-left: 5rem;
  }

  &__btn_1,
  &__btn_2 {
    grid-column: 1/-1;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    padding: 1rem 1.5rem;
    color: $color-black;
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    //max-height: 15rem;
  }
  &__btn_1 {
    background-color: lighten(lightgreen, 5%);
  }
  &__btn_2 {
    background-color: lighten(orange, 5%);
  }
}
/*
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(93, 50, 50, 0.149) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
  
  input::placeholder {
    color: #aab7c4;
  }
  
  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  
  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
  
  */
