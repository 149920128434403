.btn_notgood {
  background-color: darken($color-primary, 10%);
  color: $color-white;
  border: none;
  border-radius: 0;
  font-family: $font-display;
  font-size: 1.5rem;
  text-transform: uppercase;

  padding: 1.8rem 3rem;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    background-color: $color-primary-dark;
  }
}

.plainBtn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 2rem;
  font-weight: 500;

  &:hover {
    background-color: $color-primary-dark;
    color: inherit;
    border: none;
  }
}

.bapoo-btn {
  font-size: 1.6rem;
  text-decoration: none;
  display: inline-block;
  background-color: lighten($bappoo-color, 10%);
  border: none;
  outline: none;
  color: $color-white;
  cursor: pointer;
  padding: 1rem 3rem;
  border-radius: 0 5rem 0 5rem;
}
.bapoo-btn:hover {
  transform: scale(1.1);
}

.btn-enabled {
  background-color: lighten($bappoo-color, 5%);
}
.btn-enabled:hover {
  background-color: lighten($bappoo-color, 7%);
}

.btn-disabled {
  background-color: darken($color-grey-light-2, 5%);
}
.btn-disabled:hover {
  background-color: lighten($color-grey-light-2, 10%);
}

.btn-success {
  background-color: darken($color-success, 4%);
}
.btn-success:hover {
  background-color: lighten($color-success, 20%);
}

.bapoo-btn-round {
  font-size: 1.6rem;
  width: 13rem;
  height: 4.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  letter-spacing: 1px;
  border: none;
  border-radius: 2.3rem;
}

.disabled-btn {
  color: $color-white;
  background: $color-grey-light-2;
}

.enabled-btn {
  color: $color-white;
  background: $bappoo-color;
}

.bapoo-btn-round:focus {
  outline: none;
}

.bapoo-btn-round:hover {
  transform: scale(1.1);
}
.blue-border {
  border: 1px solid rgb(110, 178, 251);
}

.bapoo__quantity_input {
  //padding: 0px;
  height: 25px !important;
  width: 100px !important;
  text-align: center !important;
  font-size: 15px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  padding: 0 !important;
  margin-top: 7px !important;

  &__tiny {
    width: 70px !important;
  }
}

.bapoo__quantity_icon {
  margin-top: 10px;
}

.bapoo_radio_button {
  width: 30px !important;
}
