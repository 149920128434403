@import "../../../scss/variables/variables";

.nbContent {
  background-color: $color-white;
  display: grid;
  place-items: start;
  grid-template-columns: repeat(12, 1fr);

  &__logoclass {
    grid-column: 3 /-1;
    @media only screen and (max-width: $bp-medium) {
      grid-column: 1/-1;
      justify-self: start;
    }
  }
  &__logo {
    width: 25vh;
    @media only screen and (max-width: $bp-medium) {
      margin-left: 20px;
    }
  }

  &__links {
    grid-column: 1 /-1;
    justify-self: stretch;
    background-color: lighten($color-black, 10%);

    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: $bp-medium) {
      grid-auto-columns: minmax(min-content, 1fr);
    }

    &__phone {
      font-size: 2rem;
      font-weight: 400;
      color: lighten($color-red, 40%);
      margin-right: 1rem;
    }
    a {
      text-decoration: none;
      outline: none;
      color: $color-white;
      font-size: 2rem;
      margin-left: 2rem;
      padding-right: 2rem;
    }
    a.current {
      color: $color-primary;
    }
    a:hover {
      color: $color-primary;
    }
  }

  @media only screen and (max-width: $bp-medium) {
    //      place-items: start;
    grid-template-columns: 20% 80%;
    &__logo {
      width: 25vh;
    }
  }

  &__menu {
    cursor: pointer;
    display: none;

    // @media only screen and (max-width: $bp-medium) {
    //   //display: grid;
    //   //margin-right: 1rem;
    // }
  }
  &__menu:hover {
    filter: opacity(50%);
    user-select: none;
    -webkit-user-select: none;
  }
}

/*
&__dropdown {
    display: grid;
    background-color: $color-grey-dark-2;
    text-align: center;
  
    a {
      text-decoration: none;
      color: $color-white;
      font-size: 2rem;
      padding: 4rem;
    }
  
    a:hover {
      color: $color-primary;
      background-color: $color-white;
    }
  }
  */
