.backgroundImage {
  grid-column: full-start / full-end;
  grid-row: 2 / -1;
  //grid-row: 3 /4;
  background: #ffffff url("../../images/pizza_background.jpg") 0 0 repeat;

  filter: opacity(60%);

  z-index: -1000;
}

.backgroundImageDark {
  grid-column: full-start / full-end;
  grid-row: 3 /4;
  background: #ffffff url("../../images/dark_background.jpg") 0 0 repeat;

  filter: opacity(60%);

  z-index: 0;
}
