@import "../../scss/variables/variables";

.cartContent {
  display: grid;
  grid-template-rows: 7vh min-content 12vh;
  grid-template-columns: 1fr;

  &__header {
    grid-column: 1/-1;
    color: $color-black;
    font-size: 2rem;
    font-weight: 600;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    margin-right: 3rem;
  }

  &__closedMessage{
    font-size: 1.8m;
    color:orangered;
    font-style: italic;
  }

  &__center {
    grid-column: 1/-1;
  }

  &__footer {
    color: $color-coral;
    grid-column: 1/-1;
    align-items: center;
    margin-right: 3rem;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
  }
}

.grid-product {
  &__image {
    img {
      max-width: 100%;
      max-height: 4.5rem;
      border-radius: 50%;
    }
  }
}

.grid-table {
  margin: 0 auto;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  align-items: top;
  border-radius: 0.5rem;
  font-size: 1.8rem;

  @media (max-width: 800px) {
    background: transparent;
    grid-row-gap: 2rem;
    box-shadow: none;
  }

  &-row {
    display: grid;
    grid-template-columns: 1fr 5fr repeat(3, 2fr) 1fr;
  }

  &-cell-filler {
    grid-column: 1 /-1;
    border-top: 1px solid lighten(black, 40%);
  }
  &-cell {
    padding: 1rem;
    align-self: center;

    &__image {
      grid-column: 1/2;
    }
    &__desc {
      grid-column: 2/3;
      font-weight: 500;
    }
    &__price {
      grid-column: 3/4;
      color: lighten($color: $color-orangered, $amount: 10);
    }
    &__quantity {
      grid-column: 4/5;
    }
    &__total {
      grid-column: 5/6;
      color: $color-green;
    }
    &__trash {
      grid-column: 6/7;
    }
  }

  &-cell__heading {
    font-weight: 200;
  }
}

.flexcontainer {
  display: flex;

  div {
    padding: 0 5px 5px 0;
  }
}

.grid-totals-table {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 5fr 2fr;

  &-subtotal-heading {
    font-size: 2.4rem;
    justify-self: end;
  }
  &-subtotal {
    font-size: 2.2rem;
    justify-self: center;
  }
  &-taxes-heading {
    font-size: 2.4rem;

    justify-self: end;
  }
  &-taxes {
    font-size: 2.2rem;
    justify-self: center;
  }
  &-total-heading {
    border-top: 1px dashed black;
    font-size: 2.4rem;
    justify-self: end;
    color: $color-green;
    font-weight: 500;
  }
  &-total {
    border-top: 1px dashed black;
    font-size: 2.2rem;
    justify-self: center;
    color: $color-green;
    font-weight: 500;
  }
}

.grid-checkout {
  display: grid;
  margin: 0 auto;
  align-items: end;
  border-radius: 0.5rem;
  font-size: 1.8rem;

  &-row {
    display: grid;
    //grid-template-columns: 1fr 3fr repeat(3, 1fr) 1fr;
    font-weight: 400;
  }

  &-cell {
    padding: 1rem;
    &:not(:last-child) {
      border-right: 0px solid #ddd;
    }
  }
  &-button {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid green;
    border-radius: 0.6em;
    color: $color-green;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1;
    margin: 20px;
    padding: 1.2em 2.8em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }
}
