@import "../../scss/variables/variables";

$cart-position-top: 50px;
$cart-position-right: 10px;

.cart {
  &__checkbox {
    display: none;
  }

  &__button {
    position: fixed;
    top: $cart-position-top;
    right: $cart-position-right;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    z-index: 2000;
    //border: 2px solid wheat;
    //  box-shadow: 0 1rem 3rem rgba($color-black, 0.1);

    @media only screen and (max-width: $bp-medium) {
      top: 28px;
    }
  }

  &__myicon {
    position: absolute;
    margin-top: 15px;
    margin-left: 14px;
    z-index: 10000;
  }

  &__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: fixed;
    top: 65rem;
    right: 65rem;

    background-image: radial-gradient($color-black, darken($color-white, 80%));
    z-index: 1000;
    opacity: 0;

    //transition: transform 0.1s;
  }

  &__cart-no {
    position: absolute;
    min-height: 25px;
    min-width: 25px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    background: darken($color-grey-dark-2, 30%);
    color: $color-white;
    text-align: center;
    top: -3px;
    left: 45px;
    z-index: 1000;
  }

  &__panel-wrap {
    position: fixed;
    top: 2rem;
    bottom: 0;
    right: 0;
    width: 90vh;
    transform: translateX(100%);
    //transition: 0.3s ease-out;
    transition: 0.6s cubic-bezier(0.67, 0.66, 0.85, 0.9);

    z-index: 1800;

    @media only screen and (max-width: $bp-medium) {
      width: 45vh;
    }
  }
  &__panel {
    position: absolute;
    top: 2rem;
    bottom: 0;
    left: 0;
    right: 0;
    background: whitesmoke;
    color: $color-black;
    overflow: auto;
    padding: 1em;
    z-index: 1800;
  }
  &__page {
    padding: 2rem;
    margin-top: 10rem;
    font-size: 1.4rem;
  }
  &__checkbox:checked ~ &__panel-wrap {
    transform: translateX(0%);

    z-index: 1800;
  }
  &__checkbox:checked ~ &__background {
    transform: scale(80);
    opacity: 70%;
  }

  &__icon {
    &,
    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: $color-black;
      display: inline-block;
    }
    &::before,
    &::after {
      content: '"';
    }
  }
}
