@import "../../scss/variables/variables";

.chk-panel {
  grid-column: center-start / center-end;

  @media screen and (max-width: 750px) {
    grid-column: full-start / full-end;
  }

  grid-row: 3 /4;
  margin: 3rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);

  font-size: 1.8rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1fr 4fr 1fr;

  &__title {
    grid-column: 1 / -1;
    margin: 2rem;
  }

  &__content {
    grid-column: 1 / -1;
    margin: 1rem 8rem;

    @media screen and (max-width: 750px) {
      margin: 1rem 1rem;
    }
  }

  &__progress-bar {
    grid-column: 1 / -1;
    display: flex;
    margin-left: 5rem;
    margin-right: 5rem;

    margin-bottom: 3rem;
    justify-content: space-between;
  }

  &__step {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 3rem;
    border: 4px solid #fff;
    border-radius: 50%;
    background-color: #efefef;
  }

  &__step:after {
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 2.2rem;
    width: 45rem;
    height: 6px;
    content: "";
    background-color: #efefef;
    @media (max-width: 900px) {
      width: 41.5rem;
    }
    @media (max-width: 800px) {
      width: 36rem;
    }
    @media (max-width: 700px) {
      width: 18rem;
    }
  }

  &__step:before {
    color: #2e2e2e;
    position: absolute;
    top: 40px;
  }

  &__step:last-child:after {
    content: none;
  }

  &__step.active {
    background-color: $bappoo-color;
  }

  &__step.active:after {
    background-color: $bappoo-color;
  }

  &__step.active:before {
    color: $bappoo-color;
  }

  &__step.active + .step {
    background-color: $bappoo-color;
  }

  &__step.active + .step:before {
    color: $bappoo-color;
  }

  &__step:nth-child(1):before {
    content: "Contact";
  }

  &__step:nth-child(2):before {
    right: -40px;
    content: "Delivery";
  }
  &__step:nth-child(3):before {
    right: -30px;
    content: "Payment";
  }
  &__step:nth-child(4):before {
    right: 0;
    content: "Payment";
  }
}

input[type="checkbox"] {
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}

.shipping {
  padding: 5rem;
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-row-gap: 2rem;
  &__announcement {
    grid-column: 1 /-1;
    font-size: 4rem;
    color: orangered;
  }

  &__chkbox {
    grid-column: 1 /2;
    justify-self: end;
    margin-right: 4rem;
  }

  &__policy {
    grid-column: 2 /-1;
    text-decoration: underline;
  }
  &__disclaimer {
    grid-column: 1/-1;
    font-size: 1.6rem;
    font-style: italic;
  }
}

.chkcontact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // padding: 5rem;
  width: 80rem;

  @media screen and (max-width: 750px) {
    width: 40rem;
    margin-left: 5rem;
  }

  &__nameLbl {
    grid-column: 1 /-1;
  }
  &__nameTxt {
    grid-column: 1 /-1;
    color: orangered;
  }
  &__phoneLbl {
    grid-column: 1 /-1;
  }
  &__phoneTxt {
    grid-column: 1 /-1;
  }
  &__disclaimer {
    grid-column: 1 /-1;
  }

  &__error {
    color: red;
    font-size: 1.6rem;
  }
}
.chk-paynow {
  display: grid;
  // grid-template-columns: repeat(autofill, minmax(400px, 1fr));
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
  column-gap: 3rem;

  &__error {
    color: red;
    font-size: 1.8rem;
    font-weight: 500;
  }
  &__rhs {
    //align-self: center;
    background-color: lighten(lightpink, 10%);
    padding: 3rem;
  }
  &__lhs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: lighten(lightgrey, 10%);
    padding: 3rem;

    @media screen and (max-width: 750px) {
      padding: 1rem;
    }
  }

  &__logocontainer {
    grid-column: 1 /-1;
  }
  &__logo {
    img {
      width: 5rem;
    }
  }
  &__bigtext {
    font-size: 2.5rem;
    color: rgba(6, 6, 131, 0.733);
    font-weight: 700;
    font-style: italic;
  }

  &__card {
    grid-column: 1 /-1;
  }

  &__info {
    grid-column: 1 /-1;
    font-style: italic;
  }

  &__cvc {
    margin-top: 0.5rem;
    div {
      width: 14rem;
    }
  }

  &__buttons {
    margin-top: 2rem;
    grid-column: 1 /-1;
    display: flex;
    justify-content: space-around;
  }
}

.chkFooter {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  @media only screen and (max-width: 750px) {
    margin: 0 10rem;
  }
}

.disclaimer {
  font-size: 1.4rem;
  font-weight: 300;
  display: block;
  margin-top: 1rem;
  opacity: 0.5;
  color: $bapoo-disclaimer;
}

input[type="text"],
input[type="password"] {
  font-size: 1.8rem;
  width: 80%;
  //height: 5rem;
  padding-right: 4rem;
  padding-left: 1.6rem;
  color: rgba(46, 46, 46, 0.8);
  border: 1px solid rgb(225, 225, 225);
  border-radius: 4px;
  outline: none;
}

input[type="text"]:focus,
input[type="password"]:focus {
  border-color: rgb(119, 219, 119);
}

input,
.StripeElement {
  display: block;
  margin: 1rem 0 2rem 0;
  max-width: 34rem !important;
  padding: 1rem 1.4rem;
  font-size: 1.4rem;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(93, 50, 50, 0.149) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid rgb(225, 225, 225);
  outline: 0;
  border-radius: 4px;
  background: white;

  @media screen and (max-width: 850px) {
    width: 35rem;
  }
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.ElementsApp input {
  width: 10px !important;
}
