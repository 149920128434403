*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1rem = 10px ==> 10/16 = 62.5%

  @media only screen and (max-width: $bp-largest) {
    font-size: 50%;
  }

  @media only screen and (max-width: $bp-medium) {
    font-size: 45%;
  }
}

body {
  box-sizing: border-box;
  font-family: $font-primary;
  color: $color-grey-dark-3;
  font-weight: 300;
  line-height: 1.6;
}

::selection {
  background-color: $color-primary;
  color: $color-white;
}

.container {
  min-height: 100vh;
  width: 100vw;
  display: grid;

  grid-template-rows: 15vh 24vh min-content 20vh;

  grid-template-columns:
    [full-start]
    minmax(1rem, 1fr)
    [center-start]
    repeat(8, [col-start] minmax(min-content, 14rem) [col-end])
    [center-end]
    minmax(1rem, 1fr)
    [full-end];

  grid-row-gap: 0rem;

  //grid-template-columns: 1.5em minmax(40em, 1fr) 1.5em;

  /*
  grid-template-areas:
    "header header header "
    "deals deals deals "
    ". main  ."
    "footer footer footer ";
    */

  @media only screen and (max-width: $bp-large) {
    grid-template-rows: 12vh 20vh min-content 20vh;

    grid-template-columns:
      [full-start]
      minmax(1rem, 1fr)
      [center-start]
      repeat(8, [col-start] minmax(min-content, 14rem) [col-end])
      [center-end]
      minmax(1rem, 1fr)
      [full-end];

    grid-row-gap: 2rem;
  }

  @media only screen and (max-width: $bp-medium) {
    grid-template-rows:
      [header-start]12vh[header-end banner-start]
      20vh[banner-end content-start] min-content [content-end footer-start]
      15vh[footer-end];

    grid-template-columns:
      [full-start]
      minmax(1rem, 1fr)
      [center-start]
      repeat(8, [col-start] minmax(min-content, 7rem) [col-end])
      [center-end]
      minmax(1rem, 1fr)
      [full-end];

    grid-row-gap: 2rem;
  }
}

.topNav {
  //grid-area: header;
  grid-column: full-start / full-end;
}

.footer {
  background-color: $color-faint-footer-grey;
  //grid-area: footer;
  grid-column: full-start / full-end;
  grid-row: footer-start / footer-end;
}

.spinner {
  animation: spin infinite 1.5s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
