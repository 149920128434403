@import "../../scss/variables/variables";
.loginPage {
  grid-row: 3 /4;
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  font-size: 2rem;
}

.loginform {
  border: 1px solid black;
  border-radius: 5px;
  padding: 50px;
}

.btnGroup {
  display: flex;
  justify-content: flex-end;
}
