@mixin transition($speed) {
  transition: all $speed ease-in-out;
  -moz-transition: all $speed ease-in-out;
  -webkit-transition: all $speed ease-in-out;
  -o-transition: all $speed ease-in-out;
}

@mixin pizza-half-shape {
  background: lighten(#9e978e, 20%);
  display: inline-block;
  margin: 1.8rem 0.5rem 0.5rem 0;
}

@mixin customProduct_qty_container {
  margin: 10px;
  height: 40px;
  width: 150px;
  color: $color-white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: lighten(orangered, $amount: 20);
  justify-items: center;
  border-radius: 30px;
}

@mixin customProduct_qty_scale_input {
  font-size: 1.8rem;
  height: 2rem;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: none;
  outline: none;
}
