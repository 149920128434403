@import "../../scss/variables/variables";
@import "../../scss/helpers/backgroundImage";

$slider-position-top: 100px;
$slider-position-right: 10px;

.slider {
  &__checkbox {
    display: none;
  }

  &__button {
    position: fixed;
    top: $slider-position-top;
    right: $slider-position-right;
    height: 70px;
    width: 70px;
    border-radius: 10%;
    z-index: 2000;

    //border: 2px solid wheat;
    //box-shadow: 0 1rem 1rem rgba($color-black, 0.1);
  }

  &__myicon {
    position: absolute;
    margin-top: 15px;
    margin-left: 14px;
    z-index: 10000;
  }

  &__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: fixed;
    top: 65rem;
    right: 65rem;

    background-image: radial-gradient($color-black, darken($color-white, 80%));
    z-index: 1000;
    opacity: 0%;

    //transition: transform 0.1s;
  }

  &__panel-wrap {
    position: fixed;
    top: 2rem;
    bottom: 0;
    right: 0;
    width: 30vw;
    //width: calc(100vh / 2);
    transform: translateX(100%);
    transition: 0.6s ease-in;
    //transition: 0.6s cubic-bezier(0.67, 0.66, 0.85, 0.9);

    z-index: 1800;

    @media only screen and (max-width: 1000px) {
      width: 100vw;
    }
  }
  &__panel {
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 0;
    right: 0;
    background: whitesmoke;
    color: $color-black;
    overflow: auto;
    padding: 15px;
    z-index: 1800;
  }
  &__page {
    padding: 0rem;
    margin-top: 0rem;
    font-size: 1.4rem;
  }
  &__checkbox:checked ~ &__panel-wrap {
    transform: translateX(0%);

    z-index: 1800;
  }
  &__checkbox:checked ~ &__background {
    transform: scale(80);
    opacity: 50%;
  }

  &__icon {
    &,
    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: $color-black;
      display: inline-block;
    }
    &::before,
    &::after {
      content: '"';
    }
  }
}
