@import "../../scss/variables/variables";
@import "../../scss/helpers/backgroundImage";

.menus {
  padding: 2rem;
  font-size: 3rem;

  grid-column: center-start / center-end;
  grid-row: 3 /4;
  margin: 3rem 0;
  z-index: 2;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 3rem;

  &__heading {
    grid-column: 1 /-1;
    justify-self: center;
  }
  &__subheading {
    grid-column: 1 /-1;
    justify-self: right;
    font-size: 2.5rem;
    font-weight: 400;

    a {
      text-decoration: none;
      color: $color-tomato;
    }
  }
}

.flatmenus {
  font-size: 3rem;
  grid-column: center-start / center-end;
  grid-row: 3 /4;
  z-index: 2;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 3rem;

  &__banner {
    grid-column: 1 /-1;
    //  grid-row: 1/2;
    width: 100%;
    scale: 1.1;
    z-index: -1;
  }
  &__heading {
    grid-column: 1 /-1;
    //   grid-row: 1/2;
    justify-self: start;
    align-self: center;
    h4 {
      font-weight: 600;
      font-size: 3.5rem;
      margin-left: 10rem;
      padding: 0;
      font-family: $font-display;
    }
  }
}

.fmContainer {
  display: grid;
  grid-column: 1 /-1;
  grid-column-gap: 5rem;

  grid-row-gap: 3rem;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: 1fr;
  }
}
.flatmenu {
  display: grid;
  grid-template-areas:
    "myimage title"
    "myimage desc"
    "myimage prices";

  grid-template-columns: 1fr 3fr;

  align-items: center;

  &__image {
    grid-row: 1 / -1;
    grid-area: myimage;
    justify-self: center;
    img {
      max-width: 100%;
      max-height: 6rem;
      border-radius: 50%;
    }
  }
  &__title {
    grid-area: title;
    font-size: 2rem;
    font-weight: 700;
  }
  &__desc {
    grid-area: desc;
    font-size: 1.5rem;
    font-weight: 400;
    color: $color-grey-dark-2;
  }
  &__prices {
    grid-area: prices;
    font-size: 1.5rem;
    font-weight: 400;
    color: $color-tomato;
  }

  &__button {
    background-color: lighten($color-orangered, 10%);
    border: none;
    outline: none;
    color: $color-white;
    padding: 1rem 3rem;
    border-radius: 0 5rem 0 5rem;
  }

  &__button:hover {
    background-color: darken($color-orangered, 20%); /* Green */
  }
}

/*  --------  */
.products {
  font-size: 3rem;
  grid-column: center-start / center-end;
  grid-row: 3 /4;
  z-index: 2;

  //display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  //grid-gap: 3rem;

  &__banner {
    grid-column: 1 /-1;
    //  grid-row: 1/2;
    width: 100%;
    scale: 1.1;
    z-index: -1;
  }
  &__subheading {
    grid-column: 2 /-1;
    //grid-row: 2/3;
    justify-self: right;
    font-size: 2.5rem;
    font-weight: 400;

    a {
      text-decoration: none;
      color: $color-tomato;
    }
  }

  &__heading {
    grid-column: 1 /3;
    //grid-row: 2/3;
    //   grid-row: 1/2;
    justify-self: start;
    align-self: center;
    h4 {
      font-weight: 600;
      font-size: 3.5rem;
      margin-left: 10rem;
      padding: 0;
      font-family: $font-display;
    }
  }

  &__container {
    grid-column: 1/-1;
    margin: 3rem 0;
    z-index: 2;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    grid-gap: 5rem;
  }

  &__item-container {
    display: grid;
    //grid-template-rows: 400px 50px 120px 50px 100px;
    grid-column-gap: 1rem;
    border-bottom: 1px solid $color-grey-dark-2;
    @media only screen and (max-width: $bp-medium) {
      grid-template-rows: 350px 30px 50px 40px 80px;
    }
  }

  &__item {
    &-image {
      align-self: center;
      justify-self: center;
      img {
        width: 260px;
      }
    }
    &-title {
      font-size: 2.4rem;
      font-weight: 500;
      align-self: start;
      justify-self: center;
    }
    &-desc {
      align-self: start;
      justify-self: center;
      padding: 3rem;
      font-size: 1.8rem;
      color: $color-primary;
    }
    &-prices {
      align-self: end;
      justify-self: center;
      font-size: 2.5rem;
      color: $color-secondary;
    }
    &-buttons {
      align-self: end;
      justify-self: center;
      margin-bottom: 20px;
    }
  }
}
