@import "../../../scss/main.scss";

.footer {
  &__logo {
    width: 35vh;
  }

  &__container {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    align-content: space-evenly;

    @media only screen and (max-width: $bp-medium) {
      grid-auto-flow: row;
    }
  }

  &__text {
    padding-top: 5rem;
    font-size: 1.6rem;

    h3 {
      margin-left: 5rem;
      margin-bottom: 2rem;
    }
  }

  &__svg {
    max-width: 2.5rem;
    max-height: 2.5rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
    fill: $color-primary;
  }

  &__address {
    display: flex;
  }
}
