@import "../../scss//variables/variables";
@import "../../scss/helpers/backgroundImage";

.selectedProduct {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;

  &__header {
    display: flex;

    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: $slider-header-color;

    &-label {
      font-size: 2.2rem;
      font-style: normal;
      text-align: center;
      font-weight: normal;
      color: $color-white;
      display: block;
      margin-left: 5rem;
    }
    &-btn {
      color: $color-white;
      flex: 0 0 auto;
      overflow: visible;
      text-align: center;
      border-radius: 50%;
      margin-right: 2rem;
    }
  }

  &__subheader {
    padding: 20px;
    box-shadow: 0 10px 22px 0 $slider-subheader-box-shadow;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;

    &_desc {
      padding-left: 20px;
    }
  }

  &__mainImage {
    width: 70px;
    //height: 75px;
  }

  &__selectedItem {
    color: $slider-seletedItem;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2em;
  }

  &__selectedProductName {
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2em;
  }

  &__content {
    //display: flex;
    padding: 2rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &__title {
      display: flex;
      align-items: center;
      flex-direction: row;
      //justify-content: center;
      @media only screen and (max-width: 700px) {
        margin-left: 20px;
      }

      &__no {
        width: 40px;
        height: 40px;
        border: 2px solid $slider-seletedItem;
        display: flex;
        flex-shrink: 0;
        border-radius: 999px;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
      }
      &__txt {
        display: flex;
        margin-left: 15px;
        flex-direction: column;

        &-1 {
          font-size: 20px;
          font-style: normal;
          font-weight: bold;
        }
        &-2 {
          color: $slider-seletedItem;
          font-size: 17px;
          font-style: normal;
          font-weight: bold;
        }
      }
    }
    &__desc {
      //  display: flex;
      max-width: 100%;
      margin-top: 30px;
      justify-content: center;

      &-grid {
        display: grid;
        grid-template-columns: repeat((auto-fit, minmax(100px, 1fr)));
        grid-gap: 20px 20px;
        max-width: 100%;

        &-item {
          display: flex;
          position: relative;
          align-items: stretch;
          border-radius: 2px;
          flex-direction: column;
          pointer-events: auto;
          margin: 0;

          border: none;
          box-shadow: 0 4px 10px 0 $slider-item-box-shadow;
          //height: 200px;

          &-selected {
            top: -12px;
            right: -12px;
            color: $slider-item-border;
            display: flex;
            position: absolute;
            align-items: center;
            border-radius: 999px;
            justify-content: center;
            background-color: $slider-item-border;
            padding: 1px;
            font-size: 20px;
          }
          &-img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
            transition: height 0.2s ease 0s;
            //height: 120px;
          }
          &-txt {
            align-self: center;
            flex: 1;
            padding: 10px;
            display: flex;
            flex-direction: column;

            &-1 {
              color: $slider-item-border;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: bold;
              line-height: 1.5;
              font-family: Lato, sans-serif;
            }
            &-2 {
              color: $slider-seletedItem;
              font-size: 12px;
              font-style: italic;
              text-align: left;
              font-family: Lato, sans-serif;
              font-weight: 400;
              line-height: 1.5;
            }
          }
        }
        &-item-preconfigured {
          border: 2px dashed $color-primary;
          &-heading {
            align-self: center;
            color: $color-primary;
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }
  }

  &__footer {
    width: 100%;
    bottom: 0px;
    height: 56px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: $slider-header-color;

    &-btn {
      width: 100%;
      height: 100%;
      transition: all 0.3s;
      background: none;
      color: inherit;
      border: 0;
      margin: 0;
      cursor: pointer;
      display: inline-flex;
      outline: none;
      padding: 0;
      position: relative;
      align-items: center;
      flex: 1;
      display: flex;
      transition: all 0.3s;
      align-items: center;
      flex-direction: row;
      justify-content: center;

      &-amt {
        background-color: $slider-amount-bg-color !important;
        padding: 0px 10px;
        height: 60px;
        display: flex;
        align-items: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        justify-content: center;
        font-size: 17px;
        font-style: normal;
        font-weight: bold;
        color: $slider-amount-color;
        font-size: NaNrem;
        font-family: Lato, sans-serif;
        font-weight: 400;
        line-height: 1.5;
      }
      &-addCart {
        flex: 1;
        display: flex;
        font-size: 17px;
        font-style: normal;
        font-weight: normal;
        align-items: center;
        justify-content: center;
        color: $color-white;
      }
    }
  }
}
/*
main {
  display: grid;
  grid-template-columns: repeat((auto-fit, minmax(100px, 1fr)));
}

img {
  display: block;
  box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.06);
  width: 100%;
}
*/
